import React from "react";
import { Helmet } from "react-helmet";
import { SiteData } from "../consts/SiteData";
import { Container, Header, List, Table } from "semantic-ui-react";
import { MainContents } from "../components/MainContents";
import PageLayout from "../components/PageLayout";

const AboutClaritySolutionsPage = () => {
  return (
    <PageLayout>
      <Helmet>
        <html lang="ja" />
        <title>{SiteData.PAGES.ABOUT.TITLE}</title>
      </Helmet>
      <MainContents pageTitle="会社情報">
        <Container>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell>社名</Table.Cell>
                <Table.Cell>Clarity Solutions合同会社</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>代表 / 役員</Table.Cell>
                <Table.Cell>
                  <List>
                    <List.Item>宮﨑 盛華</List.Item>
                    <List.Item>川﨑 祐一郎</List.Item>
                    <List.Item>宮﨑 順一</List.Item>
                    <List.Item>藤 遥</List.Item>
                  </List>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>事業内容</Table.Cell>
                <Table.Cell>
                  <List>
                    <List.Item>
                      コンピューターシステムの各種コンサルティング
                    </List.Item>
                    <List.Item>
                      コンピューターシステムの設計・開発・保守・運用
                    </List.Item>
                    <List.Item>経営コンサルティング</List.Item>
                    <List.Item>コンテンツ制作</List.Item>
                  </List>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>設立日</Table.Cell>
                <Table.Cell>2021年2月25日</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>資本金</Table.Cell>
                <Table.Cell>9万円</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>社員数</Table.Cell>
                <Table.Cell>5名</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>所在地</Table.Cell>
                <Table.Cell>千葉県佐倉市</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Container>
        {/* <Header as="h2">
          Our Mission
          <Header.Subheader>
            Clarity Solutions の使命は、ITの力を使って世界中の笑顔をふやすことです。
          </Header.Subheader>
        </Header> */}
      </MainContents>
    </PageLayout>
  );
};

export default AboutClaritySolutionsPage;
